 // @ts-ignore
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/fileicon.css';
import './theme/style.css';
import './theme/animations.css';


const app = createApp(App)
  .use(IonicVue)
  .use(router);

  app.directive('plaintext', (el, binding) => {
    // this will be called for both `mounted` and `updated`
    if (el.nodeName=='ION-TEXTAREA') {
      if (el.value===false) el.value = ''
      if (el.value) {
        var tempDivElement = document.createElement("div");
      // Set the HTML content with the given value
        tempDivElement.innerHTML = el.value;
        el.value = tempDivElement.textContent || tempDivElement.innerText || ""
      }
    }else{
      el.innerHTML = el.innerText
    }
  })
router.isReady().then(() => {
  app.mount('#app');
});
