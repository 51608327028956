import { ref, computed, watch } from "vue";

import { isPlatform } from "@ionic/vue";
import connect from "@/plugins/connect";
import { getData, getItem, saveData, setAction} from '@/plugins/OdooService';
import {moment, setTheme} from '@/plugins/interfaceControler';
import config from '@/config'
export const isMobile = ref(isPlatform('mobile'))

const STORAGE_DEFAULT = { user: null, message: null, token: null, source: 'odoo', autoScan: true, favorites:[],cart: null, info: null }
export const state: any = ref({...reset})

watch(() => state, (val) => {
  if (isMobile.value) {
    window.localStorage.setItem(config.store_name, JSON.stringify(val.value)) //sessionStorage
  } else {
    window.sessionStorage.setItem(config.store_name, JSON.stringify(val.value)) //sessionStorage
  }
    
}, { deep: true })
if (isMobile.value) {
  state.value = JSON.parse(window.localStorage.getItem(config.store_name) || JSON.stringify(STORAGE_DEFAULT))//sessionStorage
} else{
  state.value = JSON.parse(window.sessionStorage.getItem(config.store_name) || JSON.stringify(STORAGE_DEFAULT))//sessionStorage
}
export const authenticated = computed(() => {
    if (!state.value.user || !state.value.token) return false
    return true
});
export const login_message = computed(()=>process.env.LOGIN_MESSAGE)
export const server = computed({
    get () {
      return state.value.server || 0
    },
    set (value) {
      state.value.server = value
    }
  })
export const info = computed({
    get () {
      return state.value.info || {name: '', username: ''}
    },
    set (value) {
      state.value.info = value
    }
  })
  export const message = ref()

export const accountList = computed({
    get () {
      return state.value.accounts || []
    },
    set (value) {
      state.value.accounts = value
    }
  })
  export function setAccountList(list:any){
    accountList.value = list.length > 0 ? list : []
  }
  export const userSettings = computed({
    get () {
      return state.value.settings || []
    },
    set (value) {
      state.value.settings = value
    }
  })
export const connection:any = computed(()=>config.connections[server.value])
export let axios: any = new connect(connection.value);
export const user =computed(() => state.value.user)
export const adminOrganisation =computed(() => state.value.user ? state.value.user.organisation : null)
export const organisation =computed(() => state.value.organisation)


export const isAdmin = computed(()=>{
    if (!state.value.user) return false
    return (state.value.user.organisation && state.value.user.organisation.name== config.admin.name && hasRole('Centrale besteller')) ? true : false
})
export const isHospital = computed(()=>{
  if (!state.value.user) return false
  return (state.value.organisation && state.value.user.hospital_ids && state.value.user.hospital_ids.includes(state.value.organisation.id)) ? true : false
})
export function changeConnect(){
    axios = new connect(connection.value);
}
export function hasAccount(account: Number, type='none'){
 if (!account) return true
 return accountList.value.filter((a:any)=>a.id==account || (a.id==0 && a.name==type)).length >0
}
export const token =computed(() => state.value.token)
export async function authenticate(credentials: any): Promise<any> {
    const response = await axios.post('auth/login', credentials)
    if (response.status == 'OK') {
       message.value = 'Success'
        state.value.host = credentials.host
        setUser(response.user, response.token)
        if (isAdmin.value) state.value.admin = state.value.user //setAdminuser
    } else {
        message.value = response.message == 'invalid credentials' ? 'Ongeldige logingegevens' : (response.error == 'err_invalid_user' ? 'Ongeldige portaal login' : response.message)
        return false
    }
    return state.value.user
}
export async function userinfo(path='auth/me'){
    if (!authenticated.value) return false
    try{
        //accountList.value = await axios.get(''path'')
        //console.log(accountList.value)
        const response = await axios.get(path)
        if (response.status=='OK'){
            setUser(response.contact, response.token)
        }
    }catch(e){
        
        reset()
    }
    return state.value.user
}

export function homeLink(redir = ''){
    if (redir!='') return redir
    if (!connection.value || !authenticated.value) return false
    let path = connection.value.redir 
    if (authenticated.value) {
      config.redirs.map((red:any)=> {
        if (red.role && hasRole(red.role) && user.value.roles.length==1) path = red.path 
      })
    }
    return path
  }

export function setUser(user?: any, token?: string) {
    if (!user) return state.value.user = null
    state.value.user = Object.assign({...state.value.user},user)
    state.value.organisation = user.organisation
    info.value = {name: user.name, username: user.email}
    state.value.cabinet = null
    
    //if (organisation.value.name == config.admin.name) 
    setTheme(organisation.value)
    if (token) state.value.token = token
    //loadAccounts()
}

export async function initUser(){
    if (accountList.value.length == 0) await loadAccounts()
    await loadSettings()
}
//initUser()
export async function loadAccounts(usr:any=user.value._id,client=false){
    const res:any= await getData('auth/accounts/'+ organisation.value.id + (hasRole("Beheerder") && !client ? '/admin' : '/' + usr))
    if (usr==user.value._id) accountList.value = res
    return res
}
export async function loadSettings(usr:any=user.value._id){
    const res:any= await getData('auth/settings',null,false)
    if (usr==user.value._id) userSettings.value = res
    return res
}
export async function loadUser(index:number){
  const res:any= await getItem('auth/user',index)
  setUser(res.user)
  document.location.reload()
}
export function hasRole(roles:any, any=false){
  try {
    if (roles == 'admin' && isAdmin.value) return true
    if (!roles ||  !user.value || !user.value.roles) return false
    if (!roles.filter) roles = [roles]
    return roles.filter((tag:any)=>user.value.roles.includes(tag)).length >= (any ? 0 :roles.length)
  }catch(e){
    return false
  }
}
export function hasOnlyRole(role:any){
    if (!user.value || !user.value.roles) return false
    if (user.value.roles.length !=1) return false
    return hasRole([role])
}
export function hasTag(tags:any, any=false){
    if (!tags.filter) tags = [tags]
    return tags.filter((tag:any)=>user.value.assortment.map((t:any)=>t.name).includes(tag)).length >= (any ? 0 : tags.length)
}
function reset() {
    const cart = state.value.cart ? [...state.value.cart] : null
    const info = state.value.info ? {...state.value.info} : null
    state.value = JSON.parse(JSON.stringify(STORAGE_DEFAULT))
    state.value.cart = cart
    state.value.info = info

}
export async function logout() {
    setUser()
    reset()
    await getData('auth/logout',null,false)
}
export async function forgotLogin(email:String) {
  return await getData('auth/forgot/' + email,null,false)
}
export async function changeLogin(options:any) {
  return await getData('auth/change/' + options.token,options,false)
}
export async function refreshToken() {
  return await getData('auth/refresh/' + token.value,null,false)
}
export async function updatePassword(password:string){
  if (!checkPass(password)) return false
  const ret = await updateUserField('password',password)
  return ret
}
export async function updateUserField(name:string, value:any){
  const data:any = {id:user.value._id}
  data[name] = value
  if (data.email) data.email = data.email.toLowerCase()
  const ret = await updateUser(data)
  return ret
}
export async function updateUser(data:any){
  const index = data.id
  if (data.email) data.email = data.email.toLowerCase()
  const ret = await saveData('admin/user',data)

  if (index==user.value._id) await loadSettings()
  return ret
}
export function checkPass(password:string){
    let score = 0
    if (!password) return score
    config.pwtest.map((t:any)=> {
      if (password.match(t.test)) score += t.score 
    })
    return score==1
}

export const greetingPrefix = computed(()=>{
  var currentHour:any = moment().format("HH");
  if (currentHour >= 3 && currentHour < 12){
      return "Goedemorgen";
  } else if (currentHour >= 12 && currentHour < 15){
      return "Goedemiddag";
  }   else if (currentHour >= 15 && currentHour < 24){
      return "Goedenavond";
  } else if (currentHour >= 0 || currentHour < 6){
      return "Goedenacht";
  } else {
      return "Goedendag";
  }
})

export const greeting = computed(()=>{
    const name = (user.value) ? user.value.name : ""
    return greetingPrefix.value + ' ' + name
})