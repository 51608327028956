import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import {authenticated, hasRole, organisation}  from '@/plugins/authService';
import {addStep } from "@/plugins/interfaceControler";
import { useVOnboarding } from 'v-onboarding'
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home/start"
  },
  {
    path: "/mail/view",
    name: "Odoo redirect",
    component: () => import('@/views/main/Mail.vue'),
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/LoginForm.vue'),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import('@/views/LoginForm.vue'),
  },
  {
    path: "/scan",
    name: "scan",
    component: () => import('@/views/ScanPage.vue'),
  },
  
  {
    path: "/home",
    name: " root",
    component: () => import('@/views/HomePage.vue'),
    meta: {
      requiresAuth: true,
      searchBar: true,
      title: 'Portal'
    },
    children: [
      {
        path: '',
        name: 'home',
        redirect: '/home/start',
        meta: {requiresAuth: true}
      },
      {
        name: "Start welkom",
        path: 'start',
        component: () => import('@/views/site/Dashboard.vue'),
        meta: {requiresAuth: true,
          
        },
       
      },
      {
        path: "401",
        name: "401",
        component: () => import('@/views/site/401.vue'),
      },
      {
        path: "profile",
        name: "profile",
        component: () => import('@/views/ProfilePage.vue'),
        meta: {
          requiresAuth: true,
          title: 'Profiel'
        }
      },
    ]
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import('@/views/shop/HomePage.vue'),
    meta: {
      requiresAuth: true,
      searchBar: true,
      title: 'Webshop'
    },
    children: [
      {
        path: '',
        name: 'shop redir',
        redirect: '/shop/start',
        meta: {requiresAuth: true}
      },
      {
        name: "Welkom shop",
        path: 'start',
        component: () => import('@/views/site/Dashboard.vue'),
        meta: {requiresAuth: true,
          tour: [
            {target: 'hero-card',title: 'ZXL webportal', description: 'Welkom bij de webportal van Zorgservice XL, hier volgt een korte rondleiding langs de belangrijkste onderdelen'},
            {target: 'page-header', title: 'Titelbalk', description: 'Hier vind je alle navigatie opties en de mogelijkheide to zoeken'},
            {target: 'user-menu',title: 'Gebruikersmenu', description: 'Klik hier om je gebruikers profiel en andere instellingen te wijzigen'},
            {target: 'catalog-card',title: 'Bestellingen', description: 'Kies hier uit de verschillende bestrelstromen'},
            {target: 'order-list',title: 'Bestellingen', description: 'Klik hier voor de overzichten en status van bestellingen'},
            {target: 'admin-card',title: 'Beheerders', description: 'Klik hier voor toegang tot het beheerders gedeelte'},
            {target: 'page-footer',title: 'Contactgegevens', description: 'Hier vind je alle contactgegevens van Zorgservice XL en uw ziekenhuis'},
            {target: 'help-center',title: 'Supportdesk', description: 'Gebruik deze knop voor het stellen van vragen aan de supportdesk', class:'onboard-focus'},
          ]}
      },
      {
        name: "Shoplist",
        path: 'list',
        component: () => import('@/views/shop/List.vue'),
        meta: {requiresAuth: true, role: 'Besteller', mainSearch: true}
      },
      {
        name: "Shopitem",
        path: 'list/:type',
        component: () => import('@/views/shop/List.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Winkelwagen",
        path: 'cart',
        component: () => import('@/views/shop/Cart.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Checkout",
        path: 'checkout',
        component: () => import('@/views/shop/Cart.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Externe Catalogus",
        path: 'oci',
        component: () => import('@/views/shop/OCI.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Bestellingen",
        path: 'orders',
        component: () => import('@/views/admin/OrderList.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Bestelling",
        path: 'orders/:index',
        component: () => import('@/views/main/orderItem.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "BestellingFactuur",
        path: 'order/:index/:invoice',
        component: () => import('@/views/main/orderItem.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Product",
        path: 'product/:item',
        component: () => import('@/views/shop/productPage.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Request",
        path: 'request/:ref',
        component: () => import('@/views/shop/requestPage.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "OCIRequest",
        path: 'request/oci',
        component: () => import('@/views/shop/OCI.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Request",
        path: 'request/:ref',
        component: () => import('@/views/shop/requestPage.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "BestellingOntvangst",
        path: 'receive/:index/:stock',
        component: () => import('@/views/admin/receiveItem.vue'),
        meta: {requiresAuth: true}
      },{
        name: "BestellingOntvangsten",
        path: 'receive/:index/:stock/:pack',
        component: () => import('@/views/admin/receiveItem.vue'),
        meta: {requiresAuth: true}
      },
    ]
  },
   {
    path: "/admin",
    name: "Beheer",
    component: () => import('@/views/admin/HomePage.vue'),
    meta: {
      requiresAuth: true,
      searchBar: true,
      title: 'Beheer'
    },
    children: [
      {
        path: '',
        name: 'Beheer redir',
        redirect: '/admin/start',
      },
      {
        name: "Overzicht",
        path: 'start',
        component: () => import('@/views/admin/Dashboard.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Kostenplaatsen",
        path: 'accounts',
        component: () => import('@/views/admin/accountsPage.vue')
      },
      {
        name: "Afdelingen",
        path: 'locations',
        component: () => import('@/views/admin/locationsPage.vue')
      },
      {
        name: "Procuratie",
        path: 'procuration',
        component: () => import('@/views/admin/Procuration.vue'),
        meta: {requiresAuth: true, role: 'Beheerder'}
      },
      {
        name: "Gebruikers",
        path: 'users',
        component: () => import('@/views/admin/Users.vue'),
        meta: {requiresAuth: true, role: 'Beheerder'}
      },
      {
        name: "Helpdesk",
        path: 'tickets',
        component: () => import('@/views/admin/Helpdesk.vue'),
        
      },
      {
        name: "Helpdeskticket",
        path: 'tickets/:index',
        component: () => import('@/views/admin/Helpdesk.vue'),
        
      },
      {
        name: "Projects",
        path: 'project',
        component: () => import('@/views/admin/Project.vue'),
        
      },
      {
        name: "Projectitem",
        path: 'project/:index',
        component: () => import('@/views/admin/Project.vue'),
        
      },
      {
        name: "Kastenbeheer",
        path: 'cabinets',
        component: () => import('@/views/admin/cabinetsPage.vue'),
        meta: {requiresAuth: true, role: 'Kastenbeheer'}
      },
      {
        name: "Kast",
        path: 'cabinets/:index',
        component: () => import('@/views/admin/cabinetsPage.vue'),
        meta: {requiresAuth: true, role: 'Kastenbeheer'}
      },
      {
        name: "Kasteninhoud",
        path: 'stock',
        component: () => import('@/views/admin/stockPage.vue'),
        meta: {requiresAuth: true, role: 'Kastenbeheer'}
      },
      {
        name: "Orders",
        path: 'orders',
        component: () => import('@/views/admin/OrderList.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Bestellingitem",
        path: 'orders/:index',
        component: () => import('@/views/main/orderItem.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Orderitem",
        path: 'order/:index',
        component: () => import('@/views/main/orderItem.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Orderfactuur",
        path: 'order/:index/:invoice',
        component: () => import('@/views/main/orderItem.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Bestellijst",
        path: 'orderlines',
        component: () => import('@/views/admin/OrderItemList.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Catalogus",
        path: 'catalog',
        component: () => import('@/views/admin/catalogList.vue'),
        meta: {requiresAuth: true}
      },
      
      {
        name: "Goederen ontvangst",
        path: 'receive',
        component: () => import('@/views/admin/PickList.vue'),
        meta: {requiresAuth: true, role: 'Goederen ontvangst'}
      },
      {
        name: "Bestelling Ontvangst",
        path: 'receive/:index/:stock',
        component: () => import('@/views/admin/receiveItem.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Bestelling Ontvangsten",
        path: 'receive/:index/:stock/:pack',
        component: () => import('@/views/admin/receiveItem.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Factuur Goedkeuringen",
        path: 'invoice',
        component: () => import('@/views/admin/ApprovalList.vue'),
        meta: {requiresAuth: true}
      },
    /*  
    
      {
        name: "Procuratiebeheer",
        path: 'procuration',
        component: () => import('@/views/admin/ListPage.vue'),
        meta: {requiresAuth: true, role: 'Beheerder'}
      },
      {
        name: "Kastbeheer",
        path: 'cabinets',
        component: () => import('@/views/admin/ListPage.vue')
      },
     
      {
        name: "Kastinhoud",
        path: 'stock',
        component: () => import('@/views/admin/ListPage.vue')
      },
      
      {
        name: "Locaties",
        path: 'locations',
        component: () => import('@/views/admin/ListPage.vue')
      },
      {
        name: "Todo beheer",
        path: 'todo',
        component: () => import('@/views/admin/ListPage.vue'),
        meta: {requiresAuth: true, role: 'Beheerder'}
      },*/
      {
        name: "Gebruikers pagina",
        path: 'user/:index',
        component: () => import('@/views/admin/ProfilePage.vue'),
        meta: {requiresAuth: true, role: 'Beheerder'}
      },
    ]
  },
  {
    path: "/site",
    name: "Website",
    component: () => import('@/views/site/HomePage.vue'),
    meta: {
      requiresAuth: true,
      searchBar: true,
      title: 'Beheer'
    },
    children: [
      {
        path: '',
        name: 'Website redir',
        redirect: '/site/news',
        meta: {requiresAuth: true}
      },
      {
        name: "Nieuws",
        path: 'news',
        component: () => import('@/views/site/News.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Nieuws item",
        path: 'news/:index',
        component: () => import('@/views/site/News.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "FAQ",
        path: 'faq',
        component: () => import('@/views/site/FAQ.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Hub",
        path: 'i/:root/:parent?/:index?',
        component: () => import('@/views/site/FAQ.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Hubitem",
        path: 'item/:index',
        component: () => import('@/views/site/FAQ.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "EAN",
        path: 'ean',
        component: () => import('@/views/site/ean.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "FAQ item",
        path: 'faq/:index',
        component: () => import('@/views/site/FAQ.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Tickets",
        path: 'tickets',
        component: () => import('@/views/admin/Helpdesk.vue'),
        meta: {requiresAuth: true}
      },
      {
        name: "Ticket",
        path: 'tickets/:index',
        component: () => import('@/views/admin/Helpdesk.vue'),
        meta: {requiresAuth: true}
      },
     
      //http://localhost:8080/mail/view?model=helpdesk.ticket&res_id=25955
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
function setTitle(t:string){
  document.title = t
}
router.beforeEach(async (to:any, from) => { //, from
  const q:any = to.query ? to.query : {  }
  if (to.path =="/mail/view"){//deeplink
    return {
      path: '/admin/tickets/' + q.res_id
    }
  }
  to.query = q

  setTitle(to.meta.title || to.name)
  if (to.meta && to.meta.requiresAuth && !authenticated.value) {
    q.redir = to.path
    
    return {
      name: 'login',
      query: q
    }
  }
  if (to.meta && to.meta.requiresAuth && to.meta.role && !hasRole(to.meta.role)) {
    return {
      path: '/shop/start',
      query: {message: "onvoldoende rechten"}
    }
  }

})

export default router;
