import { loading} from '@/plugins/interfaceControler';
import { ref,computed, watch} from 'vue';
import { axios,user} from '@/plugins/authService';
import {  openToast} from "@/plugins/interfaceControler";

import config from '@/config';
export const recordCount = ref(0)
export const counters:any = ref({})
export async function openFile(path:string){
    const ret = await axios.file(path)
    return ret
}

export async function getData(model: string, options:any = null, isArray = true, silent= false): Promise<any> {
       recordCount.value=0
       counters.value[model] = 0
       loading.value = !silent
       if (options && options.page==0) { //load all
        options.page=1
        options.top=100000
       }
        const list = options ? await axios.post(model,options) : await axios.get(model)
        setTimeout(()=>loading.value = false,200)
        if (list.status=='ERROR' && list.error){
            return openToast(list.error.data.message,{duration: 3000, color: 'danger'})
          }
        if (list && list.length==1 && list[0] && list[0].code == 200)  {
            return isArray ? [] : {}//error
        }
        if (list && list.records) {
            recordCount.value=list.length
            counters.value[model] = list.length
            return [...list.records]
        }
        
        if (isArray && !Array.isArray(list)) return []
        
        if (!Array.isArray(list)) counters.value[model] = list.length
        return list
    }
export async function getItem(model:string, index:number, options:any = null): Promise<any> {
    const item = options ? await axios.post( model + "/" + index,options) : await axios.get( model + "/" + index) 
    return item[0] && item.length==1 ? item[0] : item
}
export async function setAction(model:string, action:string, data:any): Promise<any> {
    const item = await axios.put(model + "/" + action, {data})
    return item[0] || item
}

export async function addFile(index:number,files:any,model:string = 'order'){
    const item = await axios.form(model + '/upload/' + index, files)
    return item
}

export async function saveRedData(model: string, data:any = {}): Promise<any> {
    if (data.id) {
        const res = await setAction(model, 'copy', [data.id])
        if (res.index) await setAction(model, 'action_archive', [res.index])
    }
    return await saveData(model, data)
}

export async function saveData(model: string, data:any = {}): Promise<any> {
    loading.value = true
    const subs:any = []
    Object.keys(data).filter((a:string)=>a.startsWith('_')).map((attr:string)=>{
        const mod:any = {...config[attr]}
        mod.data = data[attr]
        subs.push(mod)
        delete data[attr]
    })
    const response = await axios.post( model + '/save',data)
    data.id = response.index
    loading.value = false
    if (!data.index) return response
    subs.map(async(sub:any)=>{
        sub.data.map(async(item:any)=>{
            item[sub.index] = data.id
            const res = await axios.post( sub.model + '/save',item)
            item.id = res.index
        })
        })
    return data
}

export async function nameSearch(model: string, name: string): Promise<any> {
    const response = await axios.get(`catalog/search/${model}/${name}`)
    return response
}
export async function copyModel(model: string, index: number): Promise<any> {
    const response = await axios.get(`catalog/copy/${model}/${index}`)
    return response
}

export async function getOCI(){
const res = await axios.get('oci/cart')
return res
}

