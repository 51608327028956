export const loading = ref(false)
export const refreshing = ref(false)

import { ref, computed, watch, nextTick } from 'vue';
import { isPlatform, toastController, loadingController, alertController } from "@ionic/vue";
import { state, organisation, homeLink} from '@/plugins/authService';
import axios from "axios";


const errorSound = require('@/assets/error.mp3')


export function playError(){
  const audio = new Audio(errorSound);
  audio.play()
}

import config from '@/config';
//import mm from 'moment'
import mm from 'moment-timezone';
mm.locale("nl");

export const showResults = ref(false)



export function toOdooDate(d?:any){
  if (!d) return null
  return moment.utc(d).format('DD-MM-YYYY HH:mm') 
}
export function fromOdooDate(d?:any){
  return moment.tz(d,'Europe/Berlin').add(2, 'hours') //Wintertijd
}
export const initFunction:any = ref(null)
export function initPage(fn?:any){
  scrollToTop()
  if (fn) initFunction.value = fn
  console.log('INit page',initFunction.value )
  if (initFunction.value) initFunction.value()
}
export const searchValue = ref('')
export const startLink = homeLink()
export const queryError:any = ref([])
export const theme = computed({
  get () {
    return state.value.theme || 'default'
  },
  set (value) {
    state.value.theme = value
  }
})
export const printerID = computed({
  get () {
    return state.value.printer || 'default'
  },
  set (value) {
    state.value.printer = value
  }
})

//Scroll container
export const mainContent:any = ref()
export function scrollToBottom(){
  setTimeout(()=>{
    if (!mainContent.value) return false
      try {
        mainContent.value.$el.scrollToBottom(500);
    }catch(e){
      console.log(e)
    }
  },500)
}
export function scrollToTop(){
  setTimeout(()=>{
    if (!mainContent.value) return false
      try {
        mainContent.value.$el.scrollToTop(100);
    }catch(e){
      console.log(e)
    }
  },500)
}

//onboarding
export const onboarding:any = computed({
  get () {
    const val:any = window.localStorage.getItem('onboarding')
    return JSON.parse(val) || {}
    
  },
  set (value) {
    if (!value) return localStorage.removeItem('onboarding')
    window.localStorage.setItem('onboarding', JSON.stringify(value))
  }
})
const options = {
  labels: {
    previousButton: 'Vorige',
    nextButton: 'Volgende',
    finishButton: 'Klaar'
  }
}

export const steps:any = ref([
  ])
  
export function addStep(content:any){
  
  if (onboarding.value['#' + content.target]) return false
  steps.value.push({ attachTo: { element: '#' + content.target, classList: [content.class] }, content ,options})
}

export function checkStep(tag:string){
  steps.value = steps.value.filter((s:any)=>s.attachTo.element != tag)
  const opts = onboarding.value
  opts[tag] = true
  onboarding.value = opts
}

export function getConfig(type:any,value:any,def='',index='id',col='name'){
  const result = config[type].filter((c:any)=>c[index]==value)[0]
  if (!result) return def
  return result[col]
}

export function setTheme(org = organisation.value){
  if (org) return  theme.value = config.theme[org.name]
  theme.value = config.default
}

setTheme()
export const showMenu = ref(true)
export const showChatter = ref(false)
export const moment = mm
export const isMobile = ref(isPlatform('mobile'))
export const isOnline = ref(true)
export const search = ref()
export const screen = ref({width: window.screen.width, height: window.screen.height})
export const useCamera = ref(false)
export const isDevice = computed(()=>screen.value.width <800)
export const isConsole = computed({
  get () {
    return state.value.console || false
  },
  set (value) {
    state.value.console = value
  }
})

//DARKMODUS

export const darkMode = ref(false); 
toggleDarkTheme(darkMode.value)

/*if (state.value.darkMode === true || state.value.darkMode === false) {
  darkMode.value = state.value.darkMode
} else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  darkMode.value = true
}
toggleDarkTheme(darkMode.value)
watch(darkMode,(value:boolean)=>{
  toggleDarkTheme(value)
  state.value.darkMode = value
})*/

function toggleDarkTheme(shouldAdd:boolean) {
  //document.body.classList.toggle('dark', shouldAdd);
}
function handleResize(){
  screen.value = {width: window.screen.width, height: window.screen.height}
  isMobile.value = Math.min(window.screen.width, window.screen.height) < 768
}

function updateOnlineStatus() {
  isOnline.value= navigator.onLine;
}

handleResize()

window.removeEventListener("resize", handleResize);
window.removeEventListener("online", handleResize);
window.removeEventListener("offline", handleResize);
window.addEventListener("resize", handleResize);
window.addEventListener('online',  updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

window.removeEventListener("onkeydown", keyDown);
window.addEventListener('onkeydown', keyDown);



function keyDown(e:any){
  e.preventDefault();
  console.log('Zoeken')
/// check ctrl + f key
  if (e.ctrlKey === true && e.keyCode === 70/*65*/) {
    e.preventDefault();

    console.log('Ctrl + f was hit...');

    return false;
  }
}

/*FORMATTER */
export function toCurrency (value:any, round=2) {
  if (!value && value!=0) return null
  if (typeof value === "string" && parseFloat(value.replace(',','.'))) return  parseFloat(value.replace(',','.'));

  if (typeof value !== "number" || typeof value === "string") return value;
  const formatter = new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR', minimumFractionDigits: round });
 try {
  return formatter.format(value);
 }catch(e){
  return '-'
 }
}
let toast:any

/* USER INTERFACE */
export async function openToast(text:string, options = {duration:5000, color: 'primary'}){
  options = Object.assign({duration:2000, position: 'bottom'}, options)
  if (toast) await toast.dismiss()
  await nextTick()
  toast = await toastController
     .create(Object.assign({
       message: text
     },options))
   return toast.present();
}
export async function showConfirm(header:string, message:string, handler:any=null, labels= ['OK','Annuleren'], inputs:any=null, cancel:any=null) {
  const buttons:any = [{
    text: labels[0],
    id: 'confirm-button',
    handler: (e:any) =>{
      if(handler) handler(e)
    }
  }]
  if (handler) buttons.unshift({ //confirm or alert
    text: labels[1],
    role: 'cancel',
    cssClass: 'secondary',
    id: 'cancel-button',
    handler: (e:any) =>{
      if (cancel) cancel(e)
    }
  })
  const opts:any = {
    cssClass: 'my-confirm-class',
    header: header,
    message: message,
    buttons
  }
  if (inputs) opts.inputs = inputs
  const alert = await alertController
    .create(opts);
  return await alert.present();
}
export async function prompt(title:string, subtitle:string, text:string, placeholder:string,next:any, inputs:any = [
  {
    name: "description",
    type: 'textarea',
    placeholder: placeholder,
    value: text
  }], cancel:any=null,labels= ['OK','Annuleren']){
  const alert = await alertController
     .create({
       cssClass: 'my-prompt-class',
       header: title,
       subHeader: subtitle,
       inputs: inputs ,
      buttons: [
         {
           text: labels[1],
           role: 'cancel',
           cssClass: 'secondary',
           id: 'cancel-button',
           handler: (e:any) =>{
            const t:any = document.querySelectorAll('input.alert-input')[0] || document.querySelectorAll('textarea.alert-input')[0]
            if (cancel && t) cancel({e,description: t.value})
            }
         },
         {
           text: labels[0],
           id: 'confirm-button',
           handler: next,
         },
       ],
     });
   await alert.present();
   await nextTick()
   const t:any = document.querySelectorAll('input.alert-input')[0] || document.querySelectorAll('textarea.alert-input')[0]
   if (t) {
    t.focus()
    t.select()
   }
}
export const message = ref('loading');
const duration = ref(2000);
let controler:any
export async function showLoading(m:string,d=duration.value) {
  if (m) message.value = m
  await hideLoading()
  await nextTick()
  controler = await loadingController
    .create({
      duration: d,
      message: message.value,
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: true
    });
    
  await controler.present();
    
  setTimeout(function() {
    hideLoading()
  }, d);
}
export function HTMLtoText(value:string){
  var tempDivElement = document.createElement("div");
  // Set the HTML content with the given value
  tempDivElement.innerHTML = value;
  return  tempDivElement.textContent || tempDivElement.innerText || ""
}
export async function hideLoading(){
  if (!controler) return false
  await controler.dismiss()
  controler.value = false
  loading.value = false
}
export const validate = ref(false)
export const touched:any = ref([])
export function isTouched (key:string){
  return touched.value.includes(key) || validate.value
}
export function markTouched(key:string) {
  if (!isTouched(key))touched.value.push(key)
  //console.log(evt)
  //evt.srcElement.classList.add('ion-touched')
  //$refs.input.$el.classList.add('ion-touched')
}
export function printJSON(json:any, sep=', '){
  let entries = Object.entries(json), entry:any
  let result:any = []
  entries.map( ([key, val] = entry) => {
    if (key.substring(0,1)!='_') result.push(`${key}: ${val}`);
  });
  return result.join(sep)
}

export async function setFocus(ii:string,select=false,ttl=100){
  setTimeout(() => {
    const el: any = document.getElementById(ii) //querySelectorAll('[autofocus]')[0]
    if (!el) return false
    const inp:any = el.querySelector('input') || el.querySelector('textarea') //el.childNodes[0]
    if (inp) {
      try {
        inp.focus()
        if (select) inp.select()
      } catch (e) {
        console.log(e)
      }
    }
  }, ttl)
}

export function uid(ln:number) {
  return (new Date()).getTime().toString().slice(-ln)
  //return Date.now().toString(36) + Math.random().toString(36).substring(ln);
}