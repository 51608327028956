import {ref} from "vue";
import config from "@/config";
import axios from "axios";
import {token, logout, refreshToken} from '@/plugins/authService';
import { loading, isOnline, queryError, refreshing } from "@/plugins/interfaceControler";


export async function getImageFromUrl(url:any) {
  const resp = await axios.get(url)
  let text = resp.data
  var arr:any = []
  const urlRegex = /<img[^>]*src="([^"]+).jpg"[^>]*>/g ///(https?:\/\/[^\s]+)/g;
  text.replace(urlRegex, (url:any)=> {
    if (url.includes('std.lang.all') && url.includes('bigweb')) arr.push(url)
  })
  if (arr.length == 0) return false
  return arr[0].split('"')[1]
}

export async function getImageFromUrl2(url:any) {
  const resp = await axios.get(url)
  let text = resp.data
  var arr:any = []
  const urlRegex = /<img[^>]*src="([^"]+).jpeg"[^>]*>/g ///(https?:\/\/[^\s]+)/g;
  text.replace(urlRegex, (url:any)=> {
    console.log(url)
    if (url.includes('main-product-img')) arr.push(url)
  })
  if (arr.length == 0) return false
  return arr[0].split('"')[1]
}

interface Options {
  method: string;
  headers: any;
  body?:string;
  data?:any
}

class Connect {
  base: string;
  key: string;
  version: string;
  connection: any;
  constructor(connection:any={}) {
    this.connection = connection
    this.base = connection.api || config.server + '/' + connection.source
    this.key = connection.key
    this.version = connection.version
  }
  setBase(url:string){
    this.base =url
  }
   async get(path:string, params?:any, headers?:any) {
    return await this.request("GET", path, params, null,headers);
  }
  async post(path:string, body:any, headers?:any) {
    return await this.request("POST",  path, null, body, headers);
  }
  async put(path:string, body:any) {
    return await this.request("PUT", path, null, body);
  }
  async delete(path:string, body?:any) {
    return await this.request("DELETE", path, null, body);
  }
  async file(path:string) {
    if (!isOnline.value) return 
    const url = path && path.includes(':') ? path : this.base + path
    const headers:any = {
      'Content-Type': 'multipart/form-data',
      "x-api-key": this.key,
      "Accept-version": this.version
    }
    if (token.value) headers['Authorization'] = 'Bearer ' + token.value
    const response = await axios.get(url, { responseType: 'blob', headers })
    return response.data
  /*  return await this.request("POST", path, null, null, {
      "content-type": "multipart/form-data",
    } , files);*/
  }
  
  async form(path:string, files?:any) {
    if (!isOnline.value) return 
    const url = path && path.includes(':') ? path : this.base + path
    const headers:any = {
      'Content-Type': 'multipart/form-data',
      "x-api-key": this.key,
      "Accept-version": this.version
    }
    if (token.value) headers['Authorization'] = 'Bearer ' + token.value
    const resp = await axios.post(url, files, {
        headers
    })
  return resp.data
  /*  return await this.request("POST", path, null, null, {
      "content-type": "multipart/form-data",
    } , files);*/
  }
  async getr(url:string) {
    if (!isOnline.value) return 
    const resp = await axios.get(url)
  return resp.data
  /*  return await this.request("POST", path, null, null, {
      "content-type": "multipart/form-data",
    } , files);*/
  }
  /*async upload(url, name, blob, path){
    const options = {
      url,
      name: name,
      filePath: path,
      blob 
      //fileDirectory: FilesystemDirectory.Downloads,
    };
    return await Http.uploadFile();
  }*/
    async request(method:any, path:any,params?:any,body?:any, headers?:any, data?:any, repeat=false){
      if (!isOnline.value) return 
      if (!path) return false
      loading.value = true
      setTimeout(()=>loading.value = false,10000) //reset
      const defaults:any= {
        //baseURL: config.api.url,
        headers: {
            "content-type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin" : "*",
            "x-api-key": this.key,
            "Accept-version": this.version
        },
      }
      if (token.value) defaults.headers['Authorization'] = 'Bearer ' + token.value
      let url = path && path.includes(':') ? path : this.base + path
     
      const options:Options = {
        method,
        headers: Object.assign(defaults.headers,headers||{}),
      };
      if (params) url += '?' + queryParams(params)
      
      if (body) options.body = JSON.stringify({...body})
      if (data) options.body = data
      //if (headers) options.headers = headers
      let response
      try {
        response = await fetch(url,options)
        const data:any = await response.json();
        const mm = (data && data[0])?  data[0].code : 0 //Odoo session timeout
        if (!data || data.statusCode == 401 && ["err_invalid_token","err_no_token","err_expired_token"].includes(data.error) || mm==100) {
          if (!token.value) return false
          if (repeat && !refreshing.value) return  this.logMeOUt()
          if (!repeat && !refreshing.value){
            refreshing.value = true
            console.log('Ongeldige sessie, opnieuw ophalen')
            const ret = await refreshToken()
            if (ret.status=='OK') return refreshing.value = false
            console.log('Ongeldige sessie, afmelden',ret)
            this.logMeOUt()
          }
          //setTimeout(()=> this.request(method, path,params,body, headers, data,true),2000)
        }
        loading.value = false
        setError(data[0],url,options)
        return data
      }catch(e:any){
        console.log('ERROR', url,options,e.message,response)
        //setError(data[0])
        //if (!response) this.logMeOUt()
        loading.value = false
        return {status: 'ERROR', message: e.message}
      }
      
    }
    logMeOUt(){
     // const { logout} = authService();
      //logout()
      document.location = "/logout"
    }
  }
export default Connect;

function setError(error:any, url:string,options:any){
  queryError.value = queryError.value.filter((q:any)=>q.url!=url)
  if (error && error.code && !error.id){
    queryError.value.push({
      message: error.data.message,
      url : url,
      options : options
    })
    //console.log(queryError.value)
  } 
}

function queryParams(params: any) {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}